import type { Page } from "../../types/core";
import type { FC } from "react";

import * as React from "react";

import { NavigationProvider, PageProvider } from "../../contexts";
import { Component, type ComponentProps } from "../Component";
import { recursiveSearch } from "./utils";

/**
 * <PageComponent>
 */
function PageComponent(props: PageProps) {
	const {
		apiUrl,
		content,
		footer,
		header,
		languageId,
		library: { templates, components },
		pageLanguages,
		siteMetadata,
	} = props;

	const Template = templates[content.template.templateType];

	// ⚠️ WARNING️ 😱
	// Aquí estamos asumiendo que hay una sección que se llama `heroSection`
	//                                        ~~~~~~~~~~~
	const firstHeroElement = content.template.heroSection
		? content.template.heroSection.modules &&
			content.template.heroSection.modules[0]
		: undefined;

	const firstModule = firstHeroElement ? firstHeroElement.component : undefined;

	// Components (component and modules) list used by the page.
	const componentList = recursiveSearch({
		obj: content.template,
		searchKey: "component",
	});

	// Array of section names
	const sectionsName = Object.keys(content?.template).filter(
		(key) => content?.template[key] && content.template[key].component,
	);

	// Object with modules by section
	// {
	//   section01: [ 'Mod2', 'Mod4' ],
	//   section02: [ 'Mod7', 'Mod9' ],
	// }
	const sectionModules = Object.fromEntries(
		sectionsName?.map((key) => {
			const modules: Array<{ component: string }> =
				content?.template[key].modules || [];
			const moduleNames = modules.map((module) => module?.component);

			return [key, moduleNames];
		}),
	);

	return (
		<PageProvider
			activeSectionBase={content.template.activeSectionBase}
			activeSectionSlug={content.template.activeSectionSlug || "/"}
			apiUrl={apiUrl}
			breadcrumb={content.breadcrumb}
			canonicalURL={content.canonicalURL}
			componentList={componentList}
			dimensions={content.dimensions?.values}
			firstModule={firstModule}
			footer={footer}
			footerTheme={content.footerTheme}
			fullPath={content.fullPath}
			fullUrl={content.fullUrl}
			header={header}
			headerTheme={content.headerTheme}
			id={content.id}
			isHome={content.isHome}
			isIndexed={content.isIndexed}
			languageId={languageId}
			metaDescription={content.metaDescription}
			metasAdvanced={content.metasAdvanced}
			metaTitle={content.metaTitle}
			modified={content.modified}
			origin={content.origin}
			pageLanguages={pageLanguages}
			published={content.published}
			sectionModules={sectionModules}
			site={content.site}
			siteMetadata={siteMetadata}
			siteSlug={content.siteSlug}
			socialDescription={content.socialDescription}
			socialImage={content.socialImage}
			socialTitle={content.socialTitle}
			structuredData={content.structuredData}
			structuredDataContent={content.structuredDataContent}
			template={content.template}
			theme={content.theme}
			title={content.title}
		>
			<>
				{/* Header Sections */}
				{!!header && (
					<NavigationProvider>
						<Component libComponents={components} {...header} />
					</NavigationProvider>
				)}

				{/* Template Modules/Components */}
				<Template {...content.template} />

				{/* Footer Sections  */}
				{!!footer && (
					<NavigationProvider>
						<Component libComponents={components} {...footer} />
					</NavigationProvider>
				)}
			</>
		</PageProvider>
	);
}

export interface PageProps
	extends Pick<
		Page,
		| "apiUrl"
		| "footer"
		| "header"
		| "languageId"
		| "pageLanguages"
		| "siteMetadata"
	> {
	content: Pick<
		Page,
		| "breadcrumb"
		| "canonicalURL"
		| "dimensions"
		| "footerTheme"
		| "fullPath"
		| "fullUrl"
		| "headerTheme"
		| "id"
		| "isHome"
		| "isIndexed"
		| "metaDescription"
		| "metasAdvanced"
		| "metaTitle"
		| "modified"
		| "origin"
		| "published"
		| "site"
		| "siteSlug"
		| "socialDescription"
		| "socialImage"
		| "socialTitle"
		| "structuredData"
		| "structuredDataContent"
		| "template"
		| "theme"
		| "title"
	>;
	library: {
		components: Record<string, FC<Omit<ComponentProps, "libComponents">>>;
		templates: Record<string, FC<Omit<ComponentProps, "libComponents">>>;
	};
	children?: JSX.Element;
}

export { PageComponent as Page };
